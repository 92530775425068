.root {
    display: grid;
    grid-template-columns: 0.7fr 1fr 0.7fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: "left center right";
    height: 100vh;
    width: 100vw;
    position: absolute;
}
.rootTop {
    display: grid;
    grid-template-columns: 0.7fr 1fr 0.7fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: "left center right";
    position: absolute;
}

.imageHolder {
  position: absolute;
  width: 100%;
  height: 100%;
    display: flex;
    background-color: black;
    overflow: hidden;
  justify-content: center;
  align-items: center;
  will-change: transform, opacity;
}

.leftIm {
    grid-area: left;
    color: white;
    display: grid;
    cursor: pointer;
}
.center {
    grid-area: center;
}
.rightIm {
    grid-area: right;
    color: white;
    display: grid;
    cursor: pointer;
}
